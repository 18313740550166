import React from 'react';
import { useParams } from "react-router-dom";
import { useDossierBrouillon } from "../../../hooks/useDossierBrouillon";
import { useEffect, useState } from "react";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { useMapUtil } from "../../../hooks/useMapUtil";
import { Card, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Controller, set, useForm } from "react-hook-form";
import { map } from "leaflet";
import context from "react-bootstrap/esm/AccordionContext";
import { useReferentiel } from "../../../hooks/useReferentiel";
import { Referentiel } from "../../../models/Referentiel";
import Swal from "sweetalert2";
import { Brouillon } from "../../../models/Brouillon";
import logo from '../../../assets/logo.png';
import { setInvitationToken } from '../../../store/mapInfoSlice';

const schema = z.object({
	prixNetVendeur: z.string().min(1, 'Veuillez renseigner un prix net vendeur.').or(z.number().min(1, 'Veuillez renseigner un prix net vendeur.')),
    typesDeBien: z.array(z.object({
        id: z.number().min(1, 'Veuillez renseigner un type de bien.')
    })).min(1, 'Veuillez renseigner un type de bien.'),
    contexte: z.string().min(1, 'Veuillez renseigner un contexte pour votre bien.')
});

function ResponseStep2() {
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { dbToken } = routeParams;
    const { mapInfo, setStoreBrouillon, setStoreInvitationToken } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();
    const { getReferentielValues } = useReferentiel();
    const { cancelDossierBrouillonByToken, updateDossierBrouillonByToken } = useDossierBrouillon();

    /**
     * States
     */
    const [typesDeBien, setTypesDeBien] = useState<Referentiel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    /**
     * Form
     */
    const { control, register, watch, reset, formState, getValues, setValue } = useForm({
		resolver: zodResolver(schema)
	});
	const { isValid, dirtyFields, errors } = formState;
	const form = watch();

    /**
     * useEffect
     */
    useEffect(() => {
        reset(mapInfo.brouillon);
    }, [mapInfo.brouillon]);

    useEffect(() => {
        getReferentielValues('TYPE_BROUILLON_TYPE_BIEN')
            .then((response) => {
                setTypesDeBien(response);
            });
    }, []);

    /**
     * Handlers
     */
    const handleContinue = () => {
        setLoading(true);
        
        let brouillon: Brouillon = { 
            ...form as Brouillon,
            typesDeBien: form.typesDeBien.map((typeDeBien) => typeDeBien.id) as number[],
        };
        //Ajout des parcelles au brouillon
        brouillon = {
            ...brouillon,
            parcelles: mapInfo.parcelles.map((parcelle) => ({
                prefixe: parcelle.prefixe,
                section: parcelle.section,
                numero: parcelle.numero,
                surface: parcelle.surface,
                codePostal: parcelle.commune.code,
                commune: parcelle.commune.name,
                adressePostale: mapInfo.address.housenumber?mapInfo.address.housenumber+' ':'' + mapInfo.address.street != undefined?mapInfo.address.street??'Inconnue':'Inconnue',
            }))
        };

        //Enregistrement du brouillon
        updateDossierBrouillonByToken(dbToken, brouillon as Brouillon)
            .then((response) => {
                if(response.result == 'DOUBLON') {
                    Swal.fire({
                        title: 'Merci',
                        text: 'Merci d\'avoir pris le temps de compléter votre bien. Cependant, nous avons detecté que celui-ci fait déjà l\'objet d\'un dossier à votre nom dans notre base de donnée. Nous venons donc de nous assurer que celui-ci est bien toujours en ligne.',
                        icon: 'warning',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    });
                    setStoreBrouillon(null);
                }
                else if(response.result == 'NON_ELIGIBLE') {
                    Swal.fire({
                        title: 'Merci',
                        text: 'Merci d\'avoir pris le temps de compléter votre bien. Malheureusement, votre bien ne correspond pas à nos critères de sélection. Nous vous remercions pour votre confiance et restons à votre disposition pour toute question complémentaire.',
                        icon: 'warning',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    });
                    setStoreBrouillon(null);
                }
                else if(response.result == 'DOSSIER_CREE_SANS_INVITATION') {
                    Swal.fire({
                        title: 'Merci',
                        text: 'Merci d\'avoir pris le temps de compléter votre bien. Votre bien a bien été enregistré. Vous pouvez le retrouver dans votre espace personnel. Nous vous remercions pour votre confiance et restons à votre disposition pour toute question complémentaire.',
                        icon: 'success',
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    });
                    setStoreBrouillon(null);
                }
                else if(response.result == 'DOSSIER_CREE_AVEC_INVITATION') {
                    setStoreBrouillon({
                        ...mapInfo.brouillon,
                        etape: 4
                    });

                    //TODO, transférer le token de l'invitation
                    setStoreInvitationToken(response.invitation_token);
                }
                setLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Erreur',
                    text: error.detail,
                    icon: 'error'
                });
                setLoading(false);
            });
    };
    
    const handleCancel = () => {
        setStoreBrouillon({
            ...mapInfo.brouillon,
            etape: 1
        });
    }

    return (
        <Modal show={mapInfo.brouillon?.etape == 2} size="lg" fullscreen={'lg-down'} onHide={() => {}}>
            <Modal.Body>
                <Row className="mb-3">
                    <Col className="text-center">
                        <img src={logo} alt="logo" height="50" className="mb-2" />
                        <h4>Dernière étape avant le dépôt du bien sur notre portail</h4>
                    </Col>
                </Row>
                <Row className="mb-2 mt-2">
                    <Col>
                        <h6>Récapitulatif des parcelles</h6>
                    </Col>
                </Row>
                {mapInfo.parcelles?.map((parcelle) => (
                    <Row key={parcelle.id}>
                        <Col>
                            <p>{parcelle.prefixe} / {parcelle.section} / {parcelle.numero} - Surface de {parcelle.surface}m²</p>
                        </Col>
                    </Row>
                ))}
                <Row className="mt-3">
                    <Col>
                        <Form>
                            <Controller
                                render={({field}) => (
                                    <Form.Group className="mb-3" controlId="formPrixNetVendeur">
                                        <Form.Label><h6>Prix net vendeur souhaité</h6></Form.Label>
                                        <Form.Control type="number" {...field} required placeholder="" />
                                    </Form.Group>
                                )}
                                name='prixNetVendeur'
                                control={control}
                                key="prixNetVendeur"
                            />
                            <Controller
                                render={({field}) => (
                                    <Form.Group className="mb-3" controlId="formMontantHonoraires">
                                        <Form.Label><h6>Montant de vos honoraires</h6></Form.Label>
                                        <Form.Control type="number" {...field} required placeholder="" />
                                    </Form.Group>
                                )}
                                name='montantHonoraires'
                                control={control}
                                key="montantHonoraires"
                            />
                            <Controller
                                render={({field}) => (
                                    <Form.Group className="mb-3" controlId="formTypeBien">
                                        <Form.Label><h6>Type de bien</h6></Form.Label>
                                        <Form.Select className="mb-3" onChange={(e) => field.onChange(typesDeBien.filter((f) => f.id+'' == e.currentTarget.value))}>
                                            <option></option>
                                            {typesDeBien.map((typeDeBien) => (
                                                <option key={typeDeBien.id} value={typeDeBien.id} selected={field.value[0]?.id == typeDeBien.id}>{typeDeBien.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                                name='typesDeBien'
                                control={control}
                                key="typesDeBien"
                            />
                            <Controller
                                render={({field}) => (
                                    <Form.Group className="mb-3" controlId="formContexte">
                                        <Form.Label><h6>Description de votre annonce</h6></Form.Label>
                                        <Form.Control as="textarea" {...field} required rows={6} />
                                    </Form.Group>
                                )}
                                name='contexte'
                                control={control}
                                key="contexte"
                            />
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>Retour</Button>
                <Button variant="primary" onClick={handleContinue} disabled={!isValid || loading}>
                    {loading ? (
                        <><Spinner animation="border" size="sm" /> Dépôt en cours</>
                    ) : (
                        <>
                            Dépôt du dossier
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ResponseStep2;
